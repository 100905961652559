import Swiper from 'swiper'
import { Controller } from 'stimulus'
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
  static targets = ['container', 'next', 'prev', 'pagination']

  connect () {
    useIntersection(this)
  }

  appear () {
    this.swiper = new Swiper(this.containerTarget, {
      // lazy: true,
      preloadImages: false,
      loop: true,
      autoHeight: true,
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget
      },
      pagination: {
        el: this.paginationTarget,
        clickable: true
      }
    })
  }
}
