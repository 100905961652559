import '../styles/index.scss'
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import { Dropdown, Modal } from 'tailwindcss-stimulus-components'

import LazyLoad from 'vanilla-lazyload'
import Swiper, { Navigation, Pagination } from 'swiper'

// import Swiper styles
import 'swiper/swiper-bundle.css'

console.info('Bridgetown is loaded!')
const application = Application.start()
const context = require.context('./controllers', true, /.js$/)
application.load(definitionsFromContext(context))

application.register('dropdown', Dropdown)
application.register('modal', Modal)

const lazyLoadInstance = new LazyLoad({
  callback_enter: el => el.classList.remove('lazy')
})

Swiper.use([Navigation, Pagination])
