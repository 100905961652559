import ImageOverlayController from './image_overlay_controller'

export default class extends ImageOverlayController {
  show (e) {
    super.show(e)

    this.buttonTarget.classList.add('hidden')
  }

  hide (e) {
    super.hide(e)

    this.buttonTarget.classList.remove('hidden')
  }
}
