import { Controller } from 'stimulus'
import { autofill } from '@mapbox/search-js-web'

export default class extends Controller {
  initialize() {
    autofill({
      accessToken: 'pk.eyJ1IjoiaW50ZXJwb29sIiwiYSI6ImE0MTE0Njg4NDUxMzAxM2U4NmQyMzEwODIwYTE1M2JjIn0.ERxE9D6dZ7KdrpYA65Kf2g',
      options: {
        language: 'de'
      }
    })
  }
}
