import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['front', 'back']

  flip () {
    this.frontTarget.classList.remove('rotate-y-0')
    this.frontTarget.classList.add('rotate-y-180')
    this.backTarget.classList.remove('-rotate-y-180')
    this.backTarget.classList.add('rotate-y-0')
  }

  unflip () {
    this.frontTarget.classList.remove('rotate-y-180')
    this.frontTarget.classList.add('rotate-y-0')
    this.backTarget.classList.remove('rotate-y-0')
    this.backTarget.classList.add('-rotate-y-180')
  }
}
