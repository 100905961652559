import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['overlay', 'button']
  static values = {
    hidden: Boolean
  }
  static classes = ['hidden', 'shown']

  toggle () {
    this.hiddenValue = !this.hiddenValue
  }

  show (e) {
    if (e) {
      e.preventDefault()
    }
    this.hiddenValue = false
  }

  hide (e) {
    if (e) {
      e.preventDefault()
    }
    this.hiddenValue = true
  }

  hiddenValueChanged () {
    if (this.hiddenValue) {
      this.shownClass.split(' ').forEach(klass => {
        this.overlayTarget.classList.remove(klass)
      })
      this.hiddenClass.split(' ').forEach(klass => {
        this.overlayTarget.classList.add(klass)
      })
    } else {
      this.hiddenClass.split(' ').forEach(klass => {
        this.overlayTarget.classList.remove(klass)
      })
      this.shownClass.split(' ').forEach(klass => {
        this.overlayTarget.classList.add(klass)
      })
    }
  }
}
