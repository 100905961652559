import { Controller } from 'stimulus'
import simpleParallax from 'simple-parallax-js'

export default class extends Controller {
  connect () {
    new simpleParallax(this.element, {
      delay: this.data.get('delay') || 0,
      orientation: this.data.get('orientation') || 'up',
      scale: this.data.get('scale') || 1.3,
      transition: this.data.get('transition') || 'cubic-bezier(0,0,0,1)',
      overflow: this.data.get('overflow') === 'true',
      customContainer: this.data.get('customContainer') || '',
      customWrapper: this.data.get('customWrapper') || ''
    })
  }
}
